import { BehaviorSubject } from "rxjs";
import {Log} from "@src/utils/log";

export interface IBusyIndicatorState {
  isBusy: boolean;
  keys: string[];
}

const initState: IBusyIndicatorState = {
  isBusy: false,
  keys: [],
};

const busyIndicatorState = new BehaviorSubject(initState);
const sv = () => busyIndicatorState.value;
const setState = (nextState: Partial<IBusyIndicatorState>) =>
  busyIndicatorState.next({
    ...sv(),
    ...nextState,
  });

export const busyIndicatorService = {
  state: busyIndicatorState,
  setBusy: (info: string) => {
    Log.debug(`setting busy - ${info}`);
    setState({
      isBusy: true,
    });
  },
  setBusyActionWithKey: (key: string) => {
    Log.debug(`setting busy with key - ${key}`);
    const keys = [...sv().keys, key];
    setState({
      isBusy: true,
      keys: keys,
    });
  },
  setFree: (info: string) => {
    Log.debug(`setting free - ${info}`);
    if (sv().keys.length) return;
    Log.debug(`setting free completed - ${info}`);
    setState({ isBusy: false });
  },
  setFreeActionWithKey: (key: string) => {
    Log.debug(`setting free with key - ${key}`);
    const keys = sv().keys.filter((x) => x !== key);
    const isFree = keys.length === 0;
    setState({
      keys: keys,
      isBusy: !isFree,
    });

    if (!isFree)
      Log.debug(`Busy indicator busy because`, keys);
  },
  keys: {
    renderInvestments: "rendering investments",
  },
};
