import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import State from "../../../../State/appState";
import { InfoWIndowTab, InfoWindowStatusEnum } from "../../../../State/mapState";
import { MapInvestment } from "../../../models/investment";
import { INaviStore } from "../../../../State/RootReducer";
import { SyncOfferServiceInstance } from "../../../dataAccess/sync/sync-offer.service";
import {NoteServiceInstance} from "@src/app/points/windows/info-tabs/notes/effect-notes";

export interface INaviBudUrlParams {
  investmentId?: string;
  baseInvestmentId?: string;
  infoWindowStatus: string;
  tab?: string;
}

@Injectable({
  providedIn: "root",
})
export class MapQueryParserServiceService {
  constructor(private router: Router, private store: Store<INaviStore>) {}

  static async setUrl(
    params: INaviBudUrlParams,
    investment: MapInvestment,
    router: Router
  ) {
    const queryParams: INaviBudUrlParams = {
      infoWindowStatus: params.infoWindowStatus,
      investmentId: undefined,
      baseInvestmentId: undefined,
    };

    if (investment) {
      if (investment.id) {
        queryParams.investmentId = investment.id?.toString();
      } else {
        queryParams.baseInvestmentId = investment.baseInvestmentId?.toString();
      }
    }

    await router.navigate(["//points/map"], {
      queryParams,
    });
  }

  ProcessParams(paramMap: INaviBudUrlParams) {
    if (!Object.entries(paramMap).length) return;
    this.SetSelectedInvestment(paramMap);
    this.ProcessInfoWindowStatus(paramMap.infoWindowStatus);
    this.ProcessSelectedInfoWIndowTab(paramMap.tab);
  }

  private ProcessSelectedInfoWIndowTab(selectedTab: string) {
    if (selectedTab) {
      const status = InfoWIndowTab[InfoWIndowTab[selectedTab]];
      State.mapState.value.infoWindowTab.next(status as any);
    }
  }

  private ProcessInfoWindowStatus(infoWindowStatus: string) {
    if (infoWindowStatus) {
      const status = InfoWindowStatusEnum[infoWindowStatus];
      State.mapState.value.infoWindowStatus.next(status);
    }
  }

  private SetSelectedInvestment(query: INaviBudUrlParams) {
    if (!query.investmentId && !query.baseInvestmentId) return;

    let investment: MapInvestment;
    const investmentArray = [...State.data.investment.items.value];
    if (query.investmentId) {
      const id = Number.parseInt(query.investmentId, 10);
      investment = investmentArray.find((x) => x.id === id);
    } else {
      const baseInvestmentId = Number.parseInt(query.baseInvestmentId, 10);
      investment = investmentArray.find(
        (x) => x.baseInvestmentId === baseInvestmentId
      );
    }

    State.mapState.value.isFollowingUser.next(false);

    if (investment) {
      State.mapState.value.mainWindowMap.setCenter(
        MapInvestment.toGoogleMapsLatLng(investment)
      );
    }

    State.mapState.value.selectedInvestment.next(investment);
    if (investment) {
      NoteServiceInstance.loadNotes();
      SyncOfferServiceInstance.loadData();
    }
  }
}
