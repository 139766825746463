import { Injectable } from "@angular/core";
import { MapService } from "../../containers/map/map.service";
import { constructionStatuses } from "./statuses/constructionStatuses";
import { MapInvestment } from "../../models/investment";
import { Functions } from "../../services/common/functions";
import moment from "moment";
import _ from "lodash";
import {
  DateRange,
  InvestmentFilters,
} from "@src/app/points/windows/filter/state/filter-slice";
import { Store } from "@ngrx/store";
import { INaviStore } from "@src/app/State/RootReducer";
import { take } from "rxjs/operators";
import { OfferStatus } from "@src/app/points/windows/filter/statuses/offerStatus";
import { SellChancesStatus } from "@src/app/points/windows/filter/statuses/sellChancesStatus";
import { CalendarRepository } from "@src/app/points/dataAccess/calendar-repository.service";
import { UserCreationStatus } from "@src/app/points/windows/filter/statuses/userCreationStatus";
import State from "@src/app/State/appState";
import { OfferRepositoryInstance } from "@src/app/points/dataAccess/offerRepository";
import { ActivatedRoute, Router } from "@angular/router";
import { waitForObject } from "@src/utils/utils";
import { sellStatuses } from "./statuses/sellStatuses";
import { addQueryParam, removeQueryParam } from "../../../../utils/service/routerService";
import {DevelopmentStatus} from "@src/app/points/windows/filter/statuses/developmentStatus";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FilterService {

  constructor(
    private mapService: MapService,
    private store: Store<INaviStore>,
    route: ActivatedRoute
  ) {
    route.queryParams.subscribe((p) => {
      this.isFilterModeOpen = p.isFilterMode === "true";
    });
  }
  isFilterModeOpen = false;
  private filteredInvestments: MapInvestment[];
  private filters: InvestmentFilters;
  private _isFilterModeOpen = new BehaviorSubject<boolean>(false);
  isFilterModeOpen$ = this._isFilterModeOpen.asObservable();

  static isInRange(date: string, dateRange: DateRange) {
    const dateValue = moment(date);
    const startDate = moment(dateRange.start ?? new Date('0001-01-01T00:00:00Z'));
    const endDate = moment(dateRange.end ?? new Date('2100-01-01T00:00:00Z'));
    return dateValue.isBetween(startDate, endDate);
  }

  async filter(investmentsToFilter: MapInvestment[]) {
    await waitForObject(State.mapState.value.selectedFilter);
    const selectedSetting = State.mapState.value.selectedFilter.value.jsonData;

    this.filters = selectedSetting?.filters;
    if (investmentsToFilter.length === 0 || !this.filters) {
      return new Set<MapInvestment>();
    }
    this.filteredInvestments = investmentsToFilter;
    this.applyMapBound();
    if (State.mapState.value.isFollowingUser.value) {
      await this.applyDistance();
    }
    this.applyQuickSearch();
    this.applyConstructionState();
    this.applySellState();
    this.applyUpdateDate();
    this.applyPermissionDate();
    this.applyProduct();
    this.applyNextContactDate();
    await this.applyEventDateRange();
    this.applySellChances();
    await this.applyHasOffer();
    this.applyUserCreationStatus();
    this.applyDevelopmentStatus();

    return new Set(this.filteredInvestments);
  }

  clearFilterFlag = async (): Promise<any> => {
    this._isFilterModeOpen.next(false);
    await removeQueryParam("isFilterMode");
  }

  toggleWindow = async (): Promise<any> => {
    console.log(this);
    const currentStatus = this._isFilterModeOpen.getValue();
    this._isFilterModeOpen.next(!currentStatus);
    await addQueryParam({ isFilterMode: this.isFilterModeOpen ? undefined : true});
  }

  private applyQuickSearch() {
    if (!this.filters.quickSearch || this.filters.quickSearch.length === 0) {
      return;
    }
    const searchPhrase = this.filters.quickSearch.toLowerCase();
    this.filteredInvestments = this.filteredInvestments.filter((investment) => {
      for (const key in investment) {
        if (
          investment.hasOwnProperty(key) &&
          typeof investment[key] === "string" &&
          investment[key].toLowerCase().includes(searchPhrase)
        ) {
          return true;
        }
      }
      return false;
    });
  }

  private applyMapBound() {
    if (!this.filteredInvestments?.length) {
      return;
    }
    const bounds = this.mapService.map.getBounds();
    if (!bounds) {
      return;
    }

    this.filteredInvestments = this.filteredInvestments.filter((x) =>
      bounds.contains(new google.maps.LatLng(x.lat, x.lng))
    );
  }

  private async applyDistance() {
    if (!this.filters.distance) {
      return;
    }
    const userPosition = State.user.value.position;
    if (!userPosition)
      return;

    const bounds = new google.maps.Circle({
      radius: this.filters.distance * 1000,
      visible: false,
      center: Functions.toGoogleMapsLatLng(userPosition),
    }).getBounds();
    this.filteredInvestments = this.filteredInvestments.filter((x) => {
      try {
        return bounds.contains(x);
      } catch (exception) {
        console.log(`problem with filtering: Id: ${x.id} ${exception}`);
      }
    });
  }
  private applyConstructionState() {
    if (!this.filters.constructionStates.length) return;

    const searchValues = this.filters.constructionStates.map(x => x.toLowerCase());

    if (searchValues.includes("none")) {
      return;
    }

    this.filteredInvestments = this.filteredInvestments.filter(investment => {
      const investmentStatus = constructionStatuses.find(status => status.backendName === investment.buildingStatus.toLowerCase());
      return investmentStatus && searchValues.includes(investmentStatus.backendName);
    });
  }

  private applySellState() {
    if (!this.filters.sellStates || this.filters.sellStates.length === 0) return;

    const searchValues = this.filters.sellStates.map(x => x.toLowerCase());

    this.filteredInvestments = this.filteredInvestments.filter(investment => {
      const investmentSellStatus = sellStatuses.find(status => status.backendName === investment.sellStatus.toLowerCase());
      return investmentSellStatus && searchValues.includes(investmentSellStatus.backendName);
    });
  }

  private applyUpdateDate() {
    if (this.filters.updateRange?.start)
      this.filteredInvestments = this.filteredInvestments.filter((x) => {
        return FilterService.isInRange(x.lastUpdate, this.filters.updateRange);
      });
  }

  private applyPermissionDate() {
    if (this.filters.permissionRange?.start)
      this.filteredInvestments = this.filteredInvestments.filter((x) => {
        if (!x.permissionDate) return true;
        return FilterService.isInRange(
          x.permissionDate,
          this.filters.permissionRange
        );
      });
  }

  private async applyEventDateRange() {
    if (!this.filters.calendarEventRange) return;

    if (!this.filters.calendarEventRange.isEnabled) return;
    const allEvents = await CalendarRepository.getAll();
    const uniqueInvestments = new Set(
      _.chain(allEvents)
        .filter((x) =>
          FilterService.isInRange(x.start, this.filters.calendarEventRange)
        )
        .map((x) => x.investmentId)
        .uniq()
        .value()
    );

    this.filteredInvestments = this.filteredInvestments.filter((x) =>
      uniqueInvestments.has(x.id)
    );
  }

  private applyNextContactDate() {
    if (this.filters.nextContactDateRange?.start)
      this.filteredInvestments = this.filteredInvestments.filter((x) => {
        return FilterService.isInRange(
          x.nextContactDate,
          this.filters.nextContactDateRange
        );
      });
  }

  private applyProduct() {
    if (this.filters.product)
      this.filteredInvestments = this.filteredInvestments.filter((x) =>
        x.products?.includes(this.filters.product)
      );
  }

  private applySellChances() {
    if (!this.filters.sellChances.includes(SellChancesStatus.None)) {
      let sellChancesStatus = this.filters.sellChances.map((x) => parseInt(x));
      this.filteredInvestments = this.filteredInvestments.filter((x) =>
        sellChancesStatus.includes(x.sellChances)
      );
    }
  }

  private async applyHasOffer() {
    if (this.filters.offerStatus !== OfferStatus.None) {
      const allOffers = await OfferRepositoryInstance.getAll();
      const uniqueInvestments = new Set(
        _.chain(allOffers)
          .map((x) => x.investmentId)
          .uniq()
          .value()
      );

      if (this.filters.offerStatus === OfferStatus.Yes) {
        this.filteredInvestments = this.filteredInvestments.filter((x) =>
          uniqueInvestments.has(x.id)
        );
      } else {
        this.filteredInvestments = this.filteredInvestments.filter(
          (x) => !uniqueInvestments.has(x.id)
        );
      }
    }
  }

  private applyUserCreationStatus() {
    if (this.filters.createdByUserState.includes(UserCreationStatus.None))
      return;

    if (this.filters.createdByUserState.includes(UserCreationStatus.Yes)) {
      this.filteredInvestments = this.filteredInvestments.filter(
        (x) => !x.permissionDate || x.permissionDate === "0001-01-01T00:00:00Z"
      );
      return;
    }

    if (this.filters.createdByUserState.includes(UserCreationStatus.No)) {
      this.filteredInvestments = this.filteredInvestments.filter(
        (x) => x.permissionDate
      );
      return;
    }
  }

  private applyDevelopmentStatus() {
    if (this.filters.developmentState === DevelopmentStatus.None)
      return;

    const rozbudowa = ["przebudowa", "rozbudowa", "nadbudowa"];
    if (this.filters.developmentState === DevelopmentStatus.InDevelopment)
      this.filteredInvestments = this.filteredInvestments.filter(
        (x) => rozbudowa.some(name => x.intentType.toLowerCase().includes(name)));

    if (this.filters.developmentState === DevelopmentStatus.InConstruction)
      this.filteredInvestments = this.filteredInvestments.filter(
        (x) => x.intentType.toLowerCase().includes("budowa"));
  }
}
